import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";

const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .row2 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    height: 800px;
    @media (max-width: 1128px) {
      height: max-content;
    }
    @media (max-width: 475px) {
      height: max-content;
    }

    .row2-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media (max-width: 1128px) {
        width: 100%;
      }
      .subrow1 {
        display: flex;
        justify-content: space-between;
        width: 1186px;
        margin-bottom: 40px;

        @media (max-width: 1128px) {
          display: flex;
          width: max-content;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 475px) {
          display: flex;
          width: max-content;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .subrow2 {
      width: 1186px;
      display: flex;

      @media (max-width: 1128px) {
        display: flex;
        width: max-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
      }

      @media (max-width: 475px) {
        display: flex;
        width: max-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -100px;
      }
    }

    .text1-container {
      display: flex;
      flex-wrap: wrap;
      max-width: 40ch;
      height: 210px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #222222;
      margin-right: 10px;

      @media (max-width: 1128px) {
        width: 411px;
        height: max-content;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 37px;
        color: #222222;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
      }

      @media (max-width: 475px) {
        width: 311px;
        height: max-content;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #222222;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
      }
    }

    .text2-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 585px;
      height: 210px;

      @media (max-width: 1128px) {
        width: 100%;
      }

      @media (max-width: 475px) {
        height: max-content;
      }
      .text2-title,
      .text2-title-special {
        width: 510px;
        height: fit-content;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 36px;
        color: #222222;

        @media (max-width: 1128px) {
          align-self: center;
          width: 411px;
          height: 91px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 34px;
          color: #222222;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin-bottom: 24px;
        }

        @media (max-width: 475px) {
          width: 311px;
          height: 81px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #222222;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin-bottom: 24px;
        }

        .inside-text {
          width: 510px;
          height: fit-content;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
          color: #222222;

          @media (max-width: 1128px) {
            width: 411px;
            height: max-content;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 37px;
            color: #222222;
            flex: none;
            order: 1;
            flex-grow: 0;
          }

          @media (max-width: 475px) {
            width: 311px;
            height: max-content;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #222222;
            flex: none;
            order: 1;
            flex-grow: 0;
          }
        }
      }
    }
    .img1-container,
    .img2-container {
      width: fit-content;
      height: fit-content;

      @media (max-width: 1128px) {
        width: 400px;
        height: 311px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
      }

      @media (max-width: 475px) {
        width: 311px;
        height: 222px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
      }
    }
    .img1-container {
      margin-right: 10px;
      @media (max-width: 475px) {
        margin-right: 0px;
      }
    }
  }
`;

function Headquarters() {
  const intl = useIntl();

  return (
    <Style>
      <div className="row2">
        <div className="row2-container">
          <div className="subrow1">
            <div className="text1-container">
              {intl.formatMessage({
                id: "AboutUsHeadquartersParagraph",
              })}
            </div>
            <div className="text2-container">
              <div className="text2-title">
                {intl.formatMessage({
                  id: "AboutUsHeadquarters",
                })}
                <div className="inside-text">
                  2015 Main St #2, Vancouver, BC V5T 3C2, Canada
                </div>
              </div>
              <div className="text2-title">
                {intl.formatMessage({
                  id: "AboutUsOffice",
                })}
                <div className="inside-text">
                  1383 W 8th Ave, Vancouver, BC V6H 3V9, Canada
                </div>
              </div>
              <span className="text2-title-special">
                {intl.formatMessage({
                  id: "AboutUsStaff",
                })}{" "}
                <span className="inside-text">8</span>
              </span>
            </div>
          </div>
          <div className="subrow2">
            <div className="img1-container">
              <StaticImage
                src="../../images/aboutPageImages/headquartersImages/about-img1.png"
                width={600}
                quality={100}
                loading="eager"
                alt="About Us Image 1"
              />
            </div>
            <div className="img2-container">
              <StaticImage
                src="../../images/aboutPageImages/headquartersImages/about-img2.png"
                width={600}
                quality={100}
                loading="eager"
                alt="About Us Image 2"
              />
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
}

export default Headquarters;
