import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-react-intl";

const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .row3 {
    width: 100%;
    height: 330px;
    background: #fbfbfb;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;

    .row3-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .third-title {
        width: 80%;
        height: max-content;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #111111;

        @media (max-width: 1128px) {
          width: 408px;
          height: 78px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 300;
          font-size: 38px;
          line-height: 44px;
          text-align: left;
          color: #111111;
        }

        @media (max-width: 475px) {
          width: 308px;
          height: 68px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 300;
          font-size: 28px;
          line-height: 34px;
          text-align: left;
          color: #111111;
        }

        .third-title-bold {
          font-weight: bold;

          @media (max-width: 475px) {
            width: 308px;
            height: 68px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            color: #111111;
            text-align: left;
          }
        }
      }
    }

    .text-third-fr {
      margin-top: 24px;
    }
    .text-third-fr,
    .text-third-sr {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #222222;
      flex: none;
      order: 1;
      flex-grow: 0;

      @media (max-width: 475px) {
        width: 311px;
        height: 75px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #222222;
        text-align: left;
        margin: 0px 0px;
      }
      .third-row-link {
        color: #007de6;
        @media (max-width: 475px) {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
`;

function Interested() {
  const intl = useIntl();
  const lang = intl.formatMessage({
    id: "Lang",
  });

  return (
    <Style>
      <div className="row3">
        <div className="row3-container">
          <h2 className="third-title">
            {intl.formatMessage({
              id: "AboutUsMoreAboutUs",
            })}
            <span className="third-title-bold">pdfpro.com</span>
          </h2>
          <p className="text-third-sr">
            {intl.formatMessage({
              id: "AboutUsVisit",
            })}{" "}
            <a
              href={`https://pdfpro.com?utm_source=pdfcreator.${lang}&utm_medium=referral`}
              rel="noopener no referrer"
              className="third-row-link"
            >
              pdfpro.com
            </a>{" "}
          </p>
        </div>
      </div>
    </Style>
  );
}

export default Interested;
