import React from "react";
import styled from "styled-components";
import LinkedIn from "../../images/aboutPageImages/contactUsImages/LinkedIn-icon1.svg";
import QuestionMark from "../../images/aboutPageImages/contactUsImages/question-mark.svg";
import MailIcon from "../../images/aboutPageImages/contactUsImages/mail-icon.svg";
import ArrowRight from "../../images/aboutPageImages/contactUsImages/arrow-right-about.svg";
import { useIntl } from "gatsby-plugin-react-intl";

const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 64px;

  .row4 {
    display: flex;
    width: 100%;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .row4-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .row4-subrow1 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 197px;
        height: 40px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #111111;
        margin-top: 60px;

        @media (max-width: 1128px) {
          width: 408px;
          height: 44px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 54px;
          text-align: center;
          color: #111111;
          margin-top: 40px;
        }
        @media (max-width: 475px) {
          width: 308px;
          height: 34px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          color: #111111;
          margin-top: 40px;
        }
      }
      .row4-subrow2 {
        display: flex;
        align-items: center;
        width: 80%;
        justify-content: space-between;
        margin-top: 40px;

        @media (max-width: 1128px) {
          flex-direction: column;
        }

        @media (max-width: 475px) {
          flex-direction: column;
        }

        .img-row4-container {
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-self: center;

          @media (max-width: 1128px) {
            width: 60px;
            height: 60px;
          }
        }

        .img-row4 {
          width: 384px;
          height: 216px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #111111;
          flex: none;
          flex-grow: 0;
          margin: 24px 0px;
          text-decoration: none;
          border: none;
          background-color: transparent;
          cursor: pointer;

          @media (max-width: 1260px) {
            margin: 24px;
            width: max-content;
            &:nth-child(3) {
              margin-top: 48px;
            }
          }

          @media (max-width: 1128px) {
            margin: 24px;
            width: 100%;
            &:nth-child(3) {
              margin-top: 48px;
            }
          }

          @media (max-width: 475px) {
            margin: 24px;
            width: 100%;
            &:nth-child(3) {
              margin-bottom: 24px;
            }
          }
        }

        .row4-text {
          width: 222px;
          min-height: 64px;
          height: auto;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #111111;
          flex: none;
          flex-grow: 0;
          margin: 24px 0px;

          @media (max-width: 1128px) {
            &:nth-child(1) {
              width: 200px;
              height: 42px;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 34px;
              line-height: 42px;
              text-align: center;
              color: #111111;
              flex: none;
              order: 1;
              flex-grow: 0;
            }
            &:nth-child(2) {
              width: 302px;
              height: 74px;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 34px;
              line-height: 42px;
              text-align: center;
              color: #111111;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 24px 0px;
            }
            &:nth-child(3) {
              width: 336px;
              height: 74px;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 34px;
              line-height: 42px;
              text-align: center;
              color: #111111;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 24px 0px;
            }
          }

          @media (max-width: 475px) {
            &:nth-child(1) {
              width: 100px;
              height: 32px;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 32px;
              text-align: center;
              color: #111111;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 24px 0px;
            }
            &:nth-child(2) {
              width: 202px;
              height: 64px;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 32px;
              text-align: center;
              color: #111111;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 24px 0px;
            }
            &:nth-child(3) {
              width: 236px;
              height: 64px;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 32px;
              text-align: center;
              color: #111111;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 24px 0px;
            }
          }
        }
      }
    }
    .row4-text-special {
      width: 202px;
      height: 64px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #111111;
      flex: none;
      flex-grow: 0;
      margin: 24px 0px;

      @media (max-width: 1128px) {
        width: 302px;
        height: 74px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 42px;
        text-align: center;
        color: #111111;
        flex: none;
        flex-grow: 0;
        margin: 30px 0px;
      }
    }
    .icon-row4-container,
    .icon-row4-container-special {
      @media (max-width: 1128px) {
        height: 40px;
        width: 40px;
        margin: 24px 0px;
      }
    }
    .icon-row4-container-special {
      position: relative;
      top: -32px;
      @media (max-width: 1128px) {
        top: -76px;
      }
    }
  }
`;

function ContactUs() {
  function SendEmailTo() {
    if (typeof window !== "undefined") {
      window.open("mailto:ceo@pdfpro.com");
    }
  }
  const intl = useIntl();

  return (
    <Style>
      <div className="row4">
        <div className="row4-container">
          <div className="row4-subrow1">
            {intl.formatMessage({
              id: "ContactUs",
            })}
          </div>
          <div className="row4-subrow2">
            <a
              href="https://www.linkedin.com/company/zipitysoftware"
              rel="noreferrer noopener"
              target="_blank"
              className="img-row4"
            >
              <div className="img-row4-container">
                <LinkedIn className="img-row4-container" />
              </div>
              <div className="row4-text-special">LinkedIn</div>
              <div className="icon-row4-container-special">
                <ArrowRight className="icon-row4-container" />
              </div>
            </a>
            <a
              href={`https://pdfcreator.zendesk.com/hc/${intl.formatMessage({
                id: "Lang",
              })}/requests/new`}
              rel="noreferrer noopener"
              target="_blank"
              className="img-row4"
            >
              <div className="img-row4-container">
                <QuestionMark className="img-row4-container" />
              </div>
              <div className="row4-text">
                {intl.formatMessage({
                  id: "AboutUsSupport",
                })}
              </div>
              <div className="icon-row4-container">
                <ArrowRight className="icon-row4-container" />
              </div>
            </a>
            <button onClick={SendEmailTo} className="img-row4">
              <div className="img-row4-container">
                <MailIcon className="img-row4-container" />
              </div>
              <div className="row4-text">
                {intl.formatMessage({
                  id: "AboutUsManagement",
                })}
              </div>
              <div className="icon-row4-container">
                <ArrowRight className="icon-row4-container" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </Style>
  );
}

export default ContactUs;
