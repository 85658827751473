import React from "react";
import Seo from "../components/shared/seo";
import Quote from "../components/aboutPage/quote";
import Headquarters from "../components/aboutPage/headquarters";
import Interested from "../components/aboutPage/interested";
import ContactUs from "../components/aboutPage/contactUs";
import Layout from "../components/shared/layout";
import { useIntl } from "gatsby-plugin-react-intl";

function About() {
  const intl = useIntl();

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({
          id: "AboutPageTitle",
        })}
        description={intl.formatMessage({
          id: "AboutPageDescription",
        })}
      />
      <Quote />
      <Headquarters />
      <Interested />
      <ContactUs />
    </Layout>
  );
}

export default About;
