import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import LinkedInIconLogo from "../../images/aboutPageImages/quoteImages/LinkedIn-icon2.svg";
import LinkedInIconMail from "../../images/aboutPageImages/quoteImages/LinkedIn-icon3.svg";
import QuotationMarks from "../../images/aboutPageImages/quoteImages/quotation-marks.svg";
import { useIntl } from "gatsby-plugin-react-intl";

const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .row1 {
    width: 100%;
    min-height: 630px;
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid gainsboro;

    .row1-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .title {
      width: 840px;
      height: 58px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 52px;
      line-height: 58px;
      text-align: center;
      color: #111111;

      .bold-title {
        font-weight: 500;
      }
    }

    .subtitle {
      width: 507px;
      height: 40px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      line-height: 40px;
      text-align: center;
      color: #111111;

      .bold-subtitle {
        font-weight: 500;
      }
    }

    .quote-container {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px;
      width: 723px;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      margin: 10px 0;

      .quote {
        position: relative;
        width: 643px;
        height: auto;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #222222;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-top: 0px;
        margin-bottom: 12px;

        .quotation-img-container {
          position: absolute;
          top: -65px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px 0px 0px;
          width: 42px;
          height: 41.01px;
        }
      }

      .edmon-info {
        width: 643px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0px;
        height: auto;
        flex: none;
        order: 1;
        flex-grow: 1;

        .img {
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .info {
          display: flex;
          height: max-content;
          width: 318px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #222222;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0px 16px;

          .name {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            margin: 5px;
          }

          .relations {
            font-weight: 400;
          }
        }
        .icon-links-container {
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;

          .icon {
            margin: 0px 12px;
          }

          .linked-icon {
            margin-right: 10px;
          }
        }
      }
    }
  }
  /* media queries for tablet */
  @media (max-width: 1128px) {
    .row1 {
      height: max-content;

      .title {
        width: 468px;
        height: 72px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 46px;
        line-height: 50px;
        color: #111111;

        .bold {
          width: 408px;
          height: 72px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 46px;
          line-height: 50px;
          color: #111111;
        }
      }
      .subtitle {
        width: 468px;
        height: 32px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 42px;
        color: #111111;

        .subtitle-bold {
          font-weight: bold;
        }
      }
      .quote-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        width: 411px;
        height: 428px;
        background: #ffffff;
        box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        .edmon-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin-top: 20px;
          width: 100%;

          .img {
            border-radius: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .info {
            display: flex;
            height: max-content;
            width: max-content;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 35px;
            color: #222222;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 14px 0px;

            .name {
              width: 263px;
              height: max-content;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 28px;
              line-height: 38px;
              color: #222222;
              flex: none;
              align-self: stretch;
              flex-grow: 0;
              margin: 0;
            }

            .relations {
              margin: 0;
              font-weight: 400;
            }
          }
          .icon-links-container {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            margin-top: 30px;

            .linked-icon {
              margin-right: 10px;
            }
          }
        }

        .quote {
          width: 363px;
          height: 152px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          color: #222222;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;

          .quotation-img-container {
            top: -50px;
          }
        }
      }
    }
  }
  /* media queries for mobile */
  @media (max-width: 475px) {
    .row1 {
      height: max-content;

      .title {
        max-width: 308px;
        height: 72px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 36px;
        color: #111111;
        text-align: left;

        .bold {
          width: 308px;
          height: 72px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 36px;
          color: #111111;
        }
      }
      .subtitle {
        width: 308px;
        height: 32px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 32px;
        color: #111111;
        text-align: left;

        .subtitle-bold {
          font-weight: bold;
          width: 308px;
          height: 32px;
          font-family: Open Sans;
          font-style: normal;
          font-size: 20px;
          line-height: 32px;
          color: #111111;
        }
      }
      .quote-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        width: 311px;
        height: 328px;
        background: #ffffff;
        box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        .edmon-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 100%;

          .img {
            border-radius: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .info {
            display: flex;
            height: max-content;
            width: max-content;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #222222;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 8px 0px;

            .name {
              width: 263px;
              height: max-content;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #222222;
              flex: none;
              align-self: stretch;
              flex-grow: 0;
              margin: 0;
            }

            .relations {
              margin: 0;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              font-family: Open Sans;
              font-style: normal;
              flex: none;
              align-self: stretch;
              flex-grow: 0;
            }
          }
          .icon-links-container {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            margin-top: 0;

            .icon {
            }

            .linked-icon {
              margin-right: 10px;
            }
          }
        }

        .quote {
          width: 263px;
          height: 112px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: #222222;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;

          .quotation-img-container {
            top: -50px;
          }
        }
      }
    }
  }
`;

function Quote() {
  function SendEmailTo() {
    if (typeof window !== "undefined") {
      window.open("mailto:ceo@pdfpro.com");
    }
  }

  const intl = useIntl();

  return (
    <Style>
      <div className="row1">
        <div className="row1-container">
          <h1 className="title">
            {intl.formatMessage({
              id: "AboutUsTitle",
            })}
          </h1>
          <h2 className="subtitle">
            The company behind <span className="bold-subtitle">PDF Writer</span>
          </h2>
          <div className="quote-container">
            <span className="quote">
              “
              {intl.formatMessage({
                id: "AboutUsQoute",
              })}
              ”
              <div className="quotation-img-container">
                <QuotationMarks />
              </div>
            </span>
            <div className="edmon-info">
              <div className="img">
                <StaticImage
                  className="img"
                  src="../../images/aboutPageImages/quoteImages/edmon-img.png"
                  width={60}
                  quality={100}
                  loading="eager"
                  alt="Edmon Profile Picture"
                />
              </div>
              <div className="info">
                <div className="info-column">
                  <h3 className="name">Edmon Moren</h3>
                  <span className="relations">
                    {intl.formatMessage({
                      id: "AboutUsCEO",
                    })}
                  </span>
                </div>
              </div>
              <div className="icon-links-container">
                <a
                  href="https://www.linkedin.com/in/edmonmoren/"
                  rel="noreferrer noopener"
                  target="_blank"
                  className="linked-icon"
                >
                  <LinkedInIconLogo />
                </a>
                <button onClick={SendEmailTo}>
                  <LinkedInIconMail />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
}

export default Quote;
